<template>
  <b-modal
    id="remap-confirmation-modal"
    ref="remap-confirmation-modal"
    title="Remap Leads"
    size="xl"
    centered
    hide-footer
    :hide-header-close="isRemapping"
    no-close-on-backdrop
    @show="onShow"
    @hidden="isConfirmed = false"
  >
    <b-table
      show-empty
      sticky-header
      responsive
      :items="items"
      :fields="tableConfig.fields"
      :per-page="tableConfig.perPage"
      :current-page="tableConfig.currentPage"
      :sort-by="tableConfig.sortBy"
      :sort-direction="tableConfig.sortDirection"
      no-sort-reset
      no-local-sorting
    >
      <template #empty>
        <div class="text-center">
          Please select the leads you want to remap
        </div>
      </template>
      <template #cell(message)="{ item }">
        <b-spinner
          v-if="isRemapping"
          variant="primary"
          small
        />
        <template v-else>
          {{ item.message }}
        </template>
      </template>
      <template #cell(status)="{ item }">
        <b-spinner
          v-if="isRemapping"
          variant="primary"
          small
        />
        <feather-icon
          v-if="isConfirmed"
          :icon="item.status ? 'CheckIcon' : 'XIcon'"
          size="24"
          :class="{ 'check-icon': item.status, 'x-icon': !item.status }"
        />
      </template>
    </b-table>
    <b-row class="mx-0 mb-1">
      <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
        <HeroTablePagination
          v-model="tableConfig.currentPage"
          :per-page="tableConfig.perPage"
          :total-rows="tableConfig.totalRows"
          class="mb-0"
        />
      </b-col>
      <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
        <HeroTablePerPage
          v-model="tableConfig.perPage"
          :options="tableConfig.perPageOptions"
          class="mb-0"
        >
          <template #label>
            <div />
          </template>
        </HeroTablePerPage>
        <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
      </b-col>
    </b-row>

    <hr>
    <div class="d-flex justify-content-end align-items-center col-gap-4">
      <HeroButtonAction
        v-if="!isConfirmed && items.length"
        type="submit"
        variant="primary"
        class="w-auto"
        :disabled="isRemapping"
        @click="remapLeads"
      >
        Confirm
      </HeroButtonAction>
      <HeroButtonAction
        type="button"
        variant="outline-secondary"
        class="w-auto mt-0"
        :disabled="isRemapping"
        @click="$bvModal.hide('remap-confirmation-modal')"
      >
        {{ isConfirmed || !items.length ? 'Close' : 'Cancel' }}
      </HeroButtonAction>
    </div>
  </b-modal>
</template>
<script>

import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import moment from 'moment'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    HeroTableStatus,
    HeroTablePerPage,
    HeroTablePagination,
    HeroButtonAction,
  },
  props: {
    remapItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isRemapping: false,
      isConfirmed: false,
      items: [],
      tableConfig: {
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'NAME',
            key: 'name',
            thStyle: { minWidth: '300px' },
          },
          {
            label: 'EMAIL',
            key: 'email',
          },
          {
            label: 'PHONE',
            key: 'phone',
          },
          {
            label: 'MESSAGE',
            key: 'message',
          },
          {
            label: 'STATUS',
            key: 'status',
          },
        ],
      },
    }
  },
  watch: {
    isRemapping() {
      this.$emit('get-is-remapping', this.isRemapping)
    },
  },
  methods: {
    async remapLeads() {
      try {
        this.isRemapping = true

        const url = `${process.env.VUE_APP_LEAD_SERVICE_API}remapping`
        const leadIds = this.items.map(item => item.leadId)
        const response = await axiosInstance.post(url, {
          lead_ids: leadIds,
        }, { withCredentials: false })

        this.items = this.items.map(item => {
          const message = response.data.reports.find(report => report.lead_id === item.leadId)?.message || ''
          const status = message === 'Lead was successfully re-mapped with a campaign.'
          return {
            ...item,
            message,
            status,
          }
        })
        this.isConfirmed = true
        this.$emit('remap-success')
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.isRemapping = false
      }
    },

    onShow() {
      this.items = this.remapItems.map(remapItem => ({
        ...remapItem,
        message: '',
        status: false,
      }))
      this.tableConfig.totalRows = this.items.length
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .modal-backdrop {
  width: 100%;
  height: 100%;
}
.check-icon {
  polyline {
    stroke: #28A745;
  }
}

.x-icon {
  line {
    stroke: #DC3545;
  }
}
</style>
