<template>
  <div class="unmapped-leads-filter">
    <div class="header">
      <div class="title">
        Filters
      </div>
      <HeroButtonAction
        v-b-toggle.option-sidebar
        variant="outline-primary"
        type="button"
      >
        Options
      </HeroButtonAction>
    </div>
    <b-row
      class="filter-form"
      no-gutters
    >
      <b-col
        class="filter-item"
        cols="12"
        md="6"
        lg="3"
      >
        <HeroVisionCampaignSelect
          id="campaign-select"
          v-model="filter.campaignId"
          label="Campaign"
          :loading="$store.getters['HeroVisionCampaignOption/getIsLoading']"
          :options="$store.getters['HeroVisionCampaignOption/getOptions']"
          @input="onCampaignSelect"
        />
      </b-col>
      <b-col
        class="filter-item"
        cols="12"
        md="6"
        lg="3"
      >
        <HeroVueSelect
          id="channel-select"
          v-model="filter.channelId"
          v-b-tooltip.hover.top="!filter.campaignId ? 'Select campaign to use this filter.' : ''"
          label="Channel"
          :clearable="!!filter.channelId"
          :disabled="!filter.campaignId"
          :loading="$store.getters['ChannelOptions/getIsLoading']"
          :options="channelOptions"
          @input="onChannelSelect"
        />
      </b-col>
      <b-col
        class="filter-item"
        cols="12"
        md="4"
        lg="2"
      >
        <HeroVueSelect
          id="analytic-campaign-select"
          v-model="analyticCampaignIdSelected"
          v-b-tooltip.hover.top="!filter.campaignId ? 'Select campaign to use this filter.' : ''"
          label="Analytic Campaigns"
          :placeholder="analyticCampaignsPlaceholder"
          :disabled="!filter.campaignId"
          :loading="$store.getters['AnalyticCampaignOptions/getIsLoading']"
          :options="$store.getters['AnalyticCampaignOptions/getOptions']"
          :filter-by="analyticCampaignIdSelectedFilterBy"
          :close-on-select="false"
        >
          <template #option="{ option }">
            <div class="analytic-campaigns-option">
              <b-form-checkbox
                :checked="filter.analyticCampaignIds.includes(option.value)"
                class="pointer-events-none"
              >
                <div class="option-value">
                  {{ option.value }}
                </div>
                <div class="option-text">
                  {{ option.text }}
                </div>
              </b-form-checkbox>
            </div>
          </template>
        </HeroVueSelect>
      </b-col>
      <b-col
        class="filter-item"
        cols="12"
        md="8"
        lg="4"
      >
        <HeroVueSelectMultiple
          id="multiple-analytic-campaign-id-select"
          v-model="filter.analyticCampaignIds"
          label="Analytic Campaign ID"
          :no-drop="true"
          :taggable="true"
          :select-on-key-codes="[188, 13]"
        />
      </b-col>
      <b-col
        class="filter-item"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <b-form-group label-for="date-range">
          <template #label>
            <span style="font-size: 1rem; font-weight: 600;">
              Submitted Time
            </span>
          </template>
          <div class="date-range-form-group">
            <date-range-picker
              v-model="filter.dateRange"
              opens="right"
              style="width: 100%"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              show-dropdowns
            >
              <template #input="picker">
                {{ picker.startDate | dateRange }} - {{ picker.endDate | dateRange }}
              </template>
            </date-range-picker>
            <div
              v-if="filter.dateRange.startDate && filter.dateRange.endDate"
              class="x-icon-wrapper"
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                @click="filter.dateRange = {
                  startDate: null,
                  endDate: null,
                }"
              />
            </div>
          </div>
        </b-form-group>
      </b-col>
      <b-col
        class="filter-item"
        cols="12"
        sm="6"
        md="8"
        lg="3"
      >
        <HeroInputText
          id="search"
          v-model="filter.search"
          label="Search"
          placeholder="First/Last Name, Email, Phone..."
        />
      </b-col>
      <b-col
        class="filter-item"
        cols="12"
        sm="7"
        md="6"
        lg="4"
        xl="3"
      >
        <HeroVueSelect
          id="display-mapped-leads"
          v-model="filter.displayMappedLeads"
          label="Display Mode"
          :clearable="false"
          :options="displayModeOptions"
        />
      </b-col>
      <b-col class="filter-item">
        <HeroButtonAction
          variant="primary"
          type="button"
          class="btn-submit"
          :is-loading="isLoading"
          @click="$emit('submit', filter)"
        >
          Submit
        </HeroButtonAction>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroVisionCampaignSelect from '@/views/components/form/selects/HeroVisionCampaignSelect.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
// CSS
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    HeroVisionCampaignSelect,
    HeroButtonAction,
    HeroVueSelect,
    HeroInputText,
    HeroVueSelectMultiple,
    DateRangePicker,
  },
  filters: {
    dateRange(date) {
      return date ? moment(date).tz('Asia/Bangkok').format('DD MMMM YYYY') : ''
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      analyticCampaignIdSelected: '',
      filter: {
        campaignId: '',
        channelId: '',
        analyticCampaignIds: [],
        dateRange: {
          startDate: moment().subtract(1, 'months').tz('Asia/Bangkok').format('YYYY-MM-DD'),
          endDate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
        },
        search: '',
        displayMappedLeads: '0',
      },
      displayModeOptions: [
        {
          text: 'Display unmapped leads',
          value: '0',
        },
        {
          text: 'Display unmapped and mapped leads',
          value: '1',
        },
      ],
    }
  },
  computed: {
    channelOptions() {
      return [{ text: 'All Channel', value: '' }].concat(this.$store.getters['ChannelOptions/getOptions'])
    },
    analyticCampaignsPlaceholder() {
      const { analyticCampaignIds } = this.filter
      const analyticCampaignOptionValues = this.$store.getters['AnalyticCampaignOptions/getOptions'].map(analyticCampaignOption => analyticCampaignOption.value)
      const analyticCampaignIdsFiltered = analyticCampaignIds.filter(analyticCampaignId => analyticCampaignOptionValues.includes(analyticCampaignId))

      if (analyticCampaignIdsFiltered.length === 1) {
        return analyticCampaignIdsFiltered[0]
      }

      return analyticCampaignIdsFiltered.length > 1 ? 'Multiple selections' : 'All Analytic Campaign'
    },
  },
  watch: {
    analyticCampaignIdSelected() {
      if (this.analyticCampaignIdSelected) {
        if (this.filter.analyticCampaignIds.includes(this.analyticCampaignIdSelected)) {
          this.filter.analyticCampaignIds = this.filter.analyticCampaignIds.filter(analyticCampaignId => analyticCampaignId !== this.analyticCampaignIdSelected)
        } else {
          this.filter.analyticCampaignIds.push(this.analyticCampaignIdSelected)
        }
      }
    },
    // eslint-disable-next-line func-names
    'filter.analyticCampaignIds': function () {
      this.analyticCampaignIdSelected = ''
    },
  },
  methods: {
    onCampaignSelect() {
      const { campaignId } = this.filter
      this.$store.dispatch('ChannelOptions/fetchOptions', campaignId)
      this.$store.dispatch('AnalyticCampaignOptions/fetchOptions', { campaignId })
      this.filter.channelId = ''
    },

    onChannelSelect() {
      const { campaignId, channelId } = this.filter
      this.$store.dispatch('AnalyticCampaignOptions/fetchOptions', { campaignId, channelId })
    },

    analyticCampaignIdSelectedFilterBy(option, label, search) {
      const { value } = option
      return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
          || (value || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    },
  },
}
</script>

<style scoped lang="scss">
.unmapped-leads-filter {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 12px 16px;
    .title {
      font-size: 1.125rem;
      font-weight: 600;
    }
    >button {
      width: auto;
    }
  }
  .filter-form {
    row-gap: 10px;
    padding: 0 10px 16px 10px;
    .filter-item {
      padding: 0 6px 0 6px;
      .form-group {
        margin-bottom: 0;
      }
      &:last-child {
        align-self: flex-end;
      }
      .btn-submit {
        max-width: 120px;
        margin-left: auto;
      }
    }
    .analytic-campaigns-option {
      .option-value {
        font-size: 1rem;
        font-weight: 500;
      }
      .option-text {
        font-size: 0.875rem;
      }
    }
    .date-range-form-group {
      position: relative;
      max-height: 38px;
      ::v-deep.daterangepicker.show-ranges {
        @media(min-width: 768px) {
          min-width: unset;
        }
        @media(min-width: 769px) {
          min-width: 683px;
        }
      }
      .x-icon-wrapper {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: 37.98px;
        width: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
