<template>
  <div class="unmapped-leads">
    <b-card-actions
      ref="cardFilter"
      no-actions
      no-body
    >
      <TableFilter
        :is-loading="$refs.cardTable && $refs.cardTable.showLoading"
        @submit="submitFilter"
      />
    </b-card-actions>
    <b-card-actions
      ref="cardTable"
      no-actions
      no-body
      :class="{ 'empty-item': !items.length }"
    >
      <template v-if="items.length">
        <b-table
          show-empty
          sticky-header
          responsive
          :items="items"
          :fields="tableConfig.fields.filter(field => field.isActive)"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-desc="tableConfig.sortDirection === 'desc'"
          :sort-direction="tableConfig.sortDirection"
          no-sort-reset
          no-local-sorting
          thead-tr-class="thead-tr"
          :tbody-tr-class="rowClass"
          class="unmapped-leads-table"
          @sort-changed="onSortChanged"
        >
          <template #head(action)>
            <div class="d-flex align-items-center col-gap-4">
              <HeroCheckbox
                id="check-all-row"
                v-model="allChecked"
                v-b-tooltip.hover.left="isRemapping ? 'Please wait a moment. Data remapping is currently in progress.' : ''"
                :indeterminate="indeterminate"
                :disabled="isRemapping"
                class="mb-0"
                @change="toggleAllChecked"
              />
              <HeroButtonAction
                variant="primary"
                type="button"
                @click="$bvModal.show('remap-confirmation-modal')"
              >
                {{ isRemapping ? 'Remapping' : 'Remap' }} ({{ items.filter(item => item.checked).length }})
              </HeroButtonAction>
            </div>
          </template>

          <template v-for="wrapperField in wrapperFields" #[`cell(${wrapperField.key})`]="{ item }">
            <div
              :key="wrapperField.key"
              :style="`width: ${wrapperField.thStyle.minWidth }`"
            >
              {{ item[wrapperField.key] }}
            </div>
          </template>

          <template #cell(submitted_date_time)="{ item }">
            {{ formatEnglishDateTimeDefault(item.submitted_date_time) }}
          </template>

          <template #cell(last_mapped_at)="{ item }">
            {{ formatEnglishDateTimeDefault(item.last_mapped_at) }}
          </template>

          <template #cell(optimizer_names)="{ item }">
            <ul class="pl-0">
              <li
                v-for="(opt, index) in item.optimizer_names"
                :key="index"
              >
                {{ opt }}
              </li>
            </ul>
          </template>

          <template #cell(action)="{ item }">
            <HeroCheckbox
              v-if="!item.campaign_id && !item.channel_id"
              :id="item.id"
              v-model="item.checked"
              v-b-tooltip.hover.left="isRemapping ? 'Please wait a moment. Data remapping is currently in progress.' : ''"
              :disabled="isRemapping"
              class="mb-0"
            />
          </template>
        </b-table>
        <div class="d-flex align-items-center justify-content-between flex-wrap mx-0 mb-1 px-1 row-gap-3">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            class="mb-0"
            @input="debounce(getLeadList)()"
          />
          <div class="d-flex align-items-center">
            <HeroTablePerPage
              v-model="tableConfig.perPage"
              :options="tableConfig.perPageOptions"
              class="mb-0"
              @input="debounce(getLeadList)()"
            >
              <template #label>
                <div />
              </template>
            </HeroTablePerPage>
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </div>
        </div>
      </template>
      <div
        v-else
        class="text-wrapper"
      >
        {{ emptyMessage }}
      </div>
    </b-card-actions>
    <OptionSidebar
      v-model="tableConfig"
      :items="items"
    />
    <RemapConfirmationModal
      :remap-items="remapItems"
      @get-is-remapping="isRemapping = $event"
      @remap-success="getLeadList"
    />
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import TableFilter from '@/views/unmapped-leads/components/TableFilter.vue'
import OptionSidebar from '@/views/unmapped-leads/components/OptionSidebar.vue'
import RemapConfirmationModal from '@/views/unmapped-leads/components/RemapConfirmationModal.vue'
import moment from 'moment'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import DataFormatService from '@/services/DataFormatService'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import axiosInstance from '@/libs/axiosInstance'
import debounce from '@/mixins/debounce'
import Toastification from '@/services/Toastification'

export default {
  name: 'UnmappedLeads',
  components: {
    RemapConfirmationModal,
    HeroTablePerPage,
    HeroTableStatus,
    HeroTablePagination,
    HeroCheckbox,
    TableFilter,
    OptionSidebar,
    HeroButtonAction,
    BCardActions,
  },
  mixins: [debounce],
  data() {
    return {
      allChecked: false,
      isRemapping: false,
      remapItems: [],
      items: [],
      filterSubmitted: false,
      filter: {
        campaignId: '',
        channelId: '',
        analyticCampaignIds: [],
        dateRange: {
          startDate: moment().subtract(1, 'months').tz('Asia/Bangkok').format('YYYY-MM-DD'),
          endDate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
        },
        search: '',
        displayMappedLeads: '0',
      },
      tableConfig: {
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'submitted_date_time',
        sortDirection: 'desc',
        timeInterval: moment(),
        fields: [
          {
            label: 'LEAD ID',
            key: 'id',
            sortable: true,
            isActive: false,
            thStyle: { maxWidth: '120px' },
          },
          {
            label: 'LAST MAPPED TIME',
            key: 'last_mapped_at',
            sortable: true,
            isActive: false,
            thStyle: { minWidth: '210px' },
          },
          {
            label: 'SUBMITTED TIME',
            key: 'submitted_date_time',
            sortable: true,
            isActive: true,
            thStyle: { minWidth: '210px' },
          },
          {
            label: 'REMARK',
            key: 'heroaix_remark',
            isActive: true,
            thStyle: { minWidth: '330px' },
          },
          {
            label: 'ANALYTIC CAMPAIGN ID',
            key: 'analytic_campaign_id',
            sortable: true,
            isActive: true,
            thStyle: { minWidth: '175px' },
          },
          {
            label: 'ANALYTIC CAMPAIGN NAME',
            key: 'analytic_campaign_name',
            isActive: true,
            thStyle: { minWidth: '245px' },
          },
          {
            label: 'CAMPAIGN NAME',
            key: 'campaign_name',
            isActive: true,
            thStyle: { minWidth: '245px' },
          },
          {
            label: 'CHANNEL NAME',
            key: 'channel_name',
            isActive: true,
            thStyle: { minWidth: '350px' },
          },
          {
            label: 'FIRST NAME',
            key: 'form_first_name',
            sortable: true,
            isActive: true,
            thStyle: { minWidth: '150px' },
          },
          {
            label: 'LAST NAME',
            key: 'form_last_name',
            sortable: true,
            isActive: true,
            thStyle: { minWidth: '150px' },
          },
          {
            label: 'EMAIL',
            key: 'form_email',
            sortable: true,
            isActive: true,
          },
          {
            label: 'PHONE',
            key: 'form_phone',
            sortable: true,
            isActive: true,
          },
          {
            label: 'FORM ID',
            key: 'form_id',
            sortable: true,
            isActive: true,
            thStyle: { minWidth: '170px' },
          },
          {
            label: 'CS',
            key: 'account_manager_name',
            isActive: true,
            thStyle: { minWidth: '300px' },
          },
          {
            label: 'PMKT',
            key: 'optimizer_names',
            isActive: true,
            thStyle: { minWidth: '300px' },
          },
          {
            label: 'Action',
            key: 'action',
            stickyColumn: true,
            isActive: true,
            thStyle: { width: '210px', minWidth: '210px' },
          },
        ],
      },
    }
  },
  computed: {
    unmappedLeads() {
      return this.items.filter(item => !item.campaign_id && !item.channel_id)
    },
    indeterminate() {
      return this.unmappedLeads.some(item => item.checked) && !this.unmappedLeads.every(item => item.checked)
    },
    hasSomeFilters() {
      const {
        campaignId, analyticCampaignIds, dateRange, search,
      } = this.filter
      const hasDateRange = dateRange.startDate && dateRange.endDate

      return campaignId || analyticCampaignIds.length || hasDateRange || search
    },
    emptyMessage() {
      if (this.hasSomeFilters && this.filterSubmitted) {
        return 'No unmapped lead. You can select a display mode in the filter to display mapped leads.'
      }

      return 'Please filter to display the unmapped leads.'
    },
    wrapperFields() {
      return this.tableConfig.fields.filter(field => !!field.thStyle && field.key !== 'action')
    },
  },
  watch: {
    unmappedLeads: {
      handler() {
        this.allChecked = this.unmappedLeads.every(unmappedLead => unmappedLead.checked)
        this.remapItems = this.unmappedLeads.filter(unmappedLead => unmappedLead.checked).map(unmappedLead => ({
          leadId: unmappedLead.id,
          name: `${unmappedLead.form_first_name || ''} ${unmappedLead.form_last_name || ''}`,
          email: unmappedLead.form_email,
          phone: unmappedLead.form_phone,
        }))
      },
      deep: true,
    },
  },
  mounted() {
    const htmlElement = document.querySelector('html')
    htmlElement.style.zoom = '80%'

    this.$store.dispatch('HeroVisionCampaignOption/fetchOptions')
    this.$store.dispatch('ChannelOptions/fetchOptions')
    this.$store.dispatch('AnalyticCampaignOptions/fetchOptions', {})

    this.getLeadList()
  },
  beforeDestroy() {
    const htmlElement = document.querySelector('html')
    htmlElement.style.zoom = '100%'
  },
  methods: {
    formatEnglishDateTimeDefault: DataFormatService.formatEnglishDateTimeDefault,

    toggleAllChecked(checked) {
      this.items = this.items.map(item => {
        if (!item.campaign_id && !item.channel_id) {
          return {
            ...item,
            checked,
          }
        }

        return item
      })
    },

    rowClass(item) {
      return item?.campaign_id && item?.channel_id ? 'mapped-row' : ''
    },

    onSortChanged(ctx) {
      const { sortBy, sortDesc } = ctx
      this.tableConfig.sortBy = sortBy
      this.tableConfig.sortDirection = sortDesc ? 'desc' : 'asc'
      this.getLeadList()
    },

    async submitFilter(filter) {
      this.filter = { ...filter }

      if (!this.hasSomeFilters) {
        this.filterSubmitted = false
        this.$swal({ ...SweetAlert.info, text: 'Please use some filters from Campaign, Analytic Campaign ID, Date Range, or Search field before submitting.' })
        return
      }

      this.tableConfig.currentPage = 1

      await this.getLeadList()
      this.filterSubmitted = true
    },

    async getLeadList() {
      try {
        this.$refs.cardTable.showLoading = true

        const {
          campaignId,
          channelId,
          analyticCampaignIds,
          dateRange: { startDate, endDate },
          search,
          displayMappedLeads,
        } = this.filter
        const {
          currentPage, perPage, sortBy, sortDirection,
        } = this.tableConfig

        const analyticCampaignIdWithMultiKey = analyticCampaignIds.reduce((partialObj, analyticCampaignId, index) => ({
          ...partialObj,
          [`analyticCampaignIds[${index}]`]: analyticCampaignId,
        }), {})

        const response = await axiosInstance.get('/lead-service/support/unmapped-leads', {
          params: {
            campaignId: campaignId || null,
            channelId: channelId || null,
            ...analyticCampaignIdWithMultiKey,
            startDate: startDate ? moment(startDate).tz('Asia/Bangkok').format('YYYY-MM-DD') : null,
            endDate: endDate ? moment(endDate).tz('Asia/Bangkok').format('YYYY-MM-DD') : null,
            displayMappedLeads: displayMappedLeads === '1',
            q: search || null,
            page: currentPage,
            perPage,
            orderByColumn: sortBy,
            orderByDirection: sortDirection,
          },
        })

        if (response.data.status === 'warning' && response.data.message) {
          this.$toast(Toastification.getContentInfo(response.data.message))
        }

        this.tableConfig.currentPage = response.data.data.current_page || 1
        this.tableConfig.perPage = response.data.data.per_page || 1
        this.tableConfig.totalRows = response.data.data.total || 1

        this.items = response.data.data.data.map(data => ({
          ...data,
          id: data.id.toString(),
          checked: false,
        }))

        const allLeadsMapped = !!this.items.length && this.items.every(item => item.campaign_id && item.channel_id)

        this.tableConfig.fields = this.tableConfig.fields.map(field => ({
          ...field,
          isActive: field.key === 'action' ? !allLeadsMapped : field.isActive,
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.items = []
        this.tableConfig.totalRows = 0
      } finally {
        if (this.$refs.cardTable) {
          this.$refs.cardTable.showLoading = false
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.unmapped-leads {
  .empty-item {
    ::v-deep.card {
      box-shadow: none;
    }

    .text-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;
      font-weight: bold;
      background: #f8f8f8;
      height: calc(100vh - 527px);
      @media(min-width: 576px) {
        height: calc(100vh - 465px);
      }
      @media(min-width: 768px) {
        height: calc(100vh - 271px);
      }
      @media(min-width: 992px) {
        height: calc(100vh - 199px);
      }
    }
  }
  .unmapped-leads-table {
    max-height: calc(100vh - 50px);
    @media(min-width: 576px) {
      max-height: 100vh;
    }
    @media(min-width: 768px) {
      max-height: calc(100vh - 337px);
    }
    @media(min-width: 992px) {
      max-height: calc(100vh - 265px);
    }
  }
  ::v-deep.b-table-sticky-column:last-child {
    right: 0;
  }
  ::v-deep tbody {
    td > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        white-space: normal;
      }
    }
    .b-table-sticky-column:last-child {
      background-color: #f8f8f8 !important;
    }
  }
  ::v-deep.thead-tr {
    th {
      vertical-align: middle;
    }
  }
  ::v-deep.mapped-row {
    background-color: rgba(#C3E6CB, .2);
  }
}
</style>
